$black-color: #333
$white-color: #fff
$grey-color: #ddd
$light-grey-color: rgb(153, 153, 153)
$dark-grey-color: #999
$hover-bg-color: #eee

$danger-color: rgba(239,83,80,0.5)
$success-color: rgba(102,187,106,0.5)
$warning-color: rgba(255,215,64,0.8)

$search-bg: #E6F2F7
$search-border: darken($search-bg, 20%)

$chip-bg-success: #15C134
$chip-icon-bg-success: #0E8706
$chip-bg-warning: #fbc02d
$chip-icon-bg-warning: #f9a825
$chip-bg-danger: #F40101
$chip-icon-bg-danger: #A60404