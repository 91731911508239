@import './_colors.sass'

.table-actions-container
  display: flex
  justify-content: flex-end

  td
    border: none

  .table-actions
    display: flex

    button
      padding: 10px

  .MuiTable-root
    width: auto

    .MuiToolbar-root
      flex-wrap: wrap
      padding: 0

    .MuiTablePagination-selectRoot
      margin-left: 0
      margin-right: 5px

    .MuiTablePagination-select
      padding-left: 0

.search-container
  display: flex
  align-items: flex-end
  flex-wrap: wrap
  background: $search-bg
  padding: 15px
  border: 1px solid $search-border
  border-radius: 0

  .btn-container
    flex-basis: 100%
    text-align: right

  input,
  .MuiSelect-root
    font-weight: bold

  h2
    flex-basis: 100%
    margin-top: 0
    margin-bottom: 5px

  .MuiFormControl-root
    flex-grow: 1
    min-width: 200px
    margin-right: 15px
    margin-bottom: 10px
    margin-top: 10px

    // &:nth-last-child(-n+2)
    //   margin-right: 0

  .MuiInputBase-root

    button
      margin: 0

    .MuiInputBase-root
      max-width: 280px

  button
    margin-bottom: 15px
    margin-right: 10px

    &:last-child
      margin-right: 0

.main
  margin-bottom: 30px

  h1
    margin-top: 10px
    margin-bottom: 10px

  .button_mb
    margin-bottom: 20px

  .btn
    margin-top: 10px
    margin-bottom: 0
    padding: 6px 8px
    border-radius: 0
    background: #249E36
    box-shadow: none

    svg
      margin-right: 5px

    &:hover,
    &:focus
      background: lighten(#249E36, 8%)
      box-shadow: none

    span
      font-weight: 400
      color: $white-color
      font-size: 14px

  .car,
  .item
    display: flex
    flex-wrap: wrap
    margin-bottom: 5px
    padding: 15px
    border: 1px solid $grey-color
    border-radius: 0px
    background-color: $white-color
    box-shadow: none
    cursor: pointer

    &:hover
      background: $hover-bg-color

      .main-carinfoList__row

        span
          background: $hover-bg-color

    &:last-child
      margin-bottom: 0

    &__img-container
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 200px
      margin-right: 0
      padding: 5px
      background: $white-color
      border: 1px solid $grey-color
      overflow: hidden
      margin-bottom: 5px

      img
        width: 100%
        height: 100%
        object-fit: cover

    &__title
      display: flex
      justify-content: space-between
      flex-wrap: wrap

    &__footer
      margin-top: 10px
      display: flex
      align-items: center

    &__text
      flex-grow: 1

      h3
        display: flex
        align-items: center
        flex-wrap: wrap
        margin-top: 0
        margin-bottom: 0
        font-size: 20px
        font-weight: 700
        justify-content: space-between

      p
        margin-top: 0
        // line-height: 5px
        margin-bottom: 0
        color: $light-grey-color
        font-size: 15px
        font-weight: 400

        &:last-of-type
          margin-bottom: 5px

  .tab-container
    background: $hover-bg-color

  .car-view
    padding: 20px 10px 10px 10px

    .MuiFormControl-root
      margin-bottom: 10px

    .btn
      margin-top: 0

    &__container
      padding-top: 1px
      border: 1px solid $grey-color

      .MuiTabs-root
        margin-bottom: 20px

    &__title
      display: flex
      align-items: center
      margin-top: 10px
      margin-bottom: 10px

      .btn
        margin-top: 0

      button
        margin-right: 10px

      h1
        margin: 0
        font-size: 30px

    #contained-button-file
      display: none

    .btn-container
      margin-bottom: 10px

      &_status
        margin-bottom: 0

        button
          margin-right: 10px

        svg
          width: 32px
          height: 32px

        svg.success
          fill: $success-color

        svg.danger
          fill: $danger-color

        svg.warning
          fill: $warning-color

      label
        margin-right: 20px

    &__photos
      display: flex
      flex-wrap: wrap
      justify-content: flex-start
      align-items: flex-end

      &:nth-child(5n)
        margin-right: 0

  .features,
  .options
    display: flex
    flex-wrap: wrap

    &__item
      width: 396px
      margin-right: 0

    .btn
      margin-top: 5px

  .ppp-request
    padding: 15px

    h2
      margin-top: 0
      font-weight: normal

    .MuiButtonBase-root.btn
      margin-top: 0

    .car-view__photos
      margin-top: 10px

    .btn-container

      button
        margin-top: 10px
        margin-right: 10px

    .MuiFormControl-root
      width: 100%

    #upload-photos
      display: none

  .requests

    &__item
      display: flex
      flex-direction: column
      margin-bottom: 10px
      padding: 15px
      background: $white-color
      border: 1px solid $grey-color

      &_no-color:hover
        background: $hover-bg-color
        cursor: pointer

      h3
        margin-top: 0
        margin-bottom: 0
        font-size: 24px

        ~ p:first-of-type
          color: #999

      p
        margin-top: 5px
        margin-bottom: 0

      .chip
        width: fit-content
        margin-top: 10px
        margin-right: 0
        background: $dark-grey-color

    &__result
      display: flex
      align-items: center
      margin-top: 10px
      font-size: 20px

      &_warning
        color: $chip-bg-warning

      &_danger
        color: $chip-bg-danger

      &_success
        color: $chip-bg-success

      svg
        margin-right: 5px

      h4
        margin: 0

  .MuiBadge-anchorOriginTopRightRectangle
    transform: scale(1) translate(40%, 0%)

.decline-dialog

  .MuiPaper-root
    padding: 15px

    .MuiDialogTitle-root
      margin-bottom: 10px
      padding: 0
      text-align: center

    button
      margin-top: 10px

.MuiBadge-root
  margin-top: 10px

.quote
  display: flex
  margin-top: 10px
  color: $dark-grey-color
  font-size: 22px

.car-info
  padding: 15px
  border: 1px solid $grey-color

  &__title

    h2
      margin-bottom: 0
      margin-top: 0
      font-size: 24px

    p
      margin: 0
      color: $dark-grey-color
      font-size: 18px

  &__text
    display: flex
    flex-wrap: wrap
    margin-top: 15px
    margin-bottom: 20px
    padding: 0 10px 10px 10px
    background: $search-bg
    border: 1px solid $search-border

    &-item
      display: flex
      justify-content: space-between
      flex-grow: 1
      min-width: 200px
      margin-right: 10px
      border-bottom: 1px dotted $grey-color

      span
        background: $search-bg
        margin-top: 10px
        margin-bottom: -1px

        &:nth-of-type(2n)
          font-weight: bold

.title-with-button
  display: flex
  justify-content: space-between
  flex-wrap: wrap


.MuiSnackbar-root

  .MuiSnackbarContent-root
    // background: #43A047

    #message-id
      display: flex
      align-items: center

      svg
        margin-right: 10px

.delete-icon.MuiSvgIcon-root
  position: absolute
  top: 5px
  right: 5px
  fill: $white-color
  background: rgba(0,0,0,0.2)

  &:hover
    background: rgba(0,0,0,0.5)

.sortable-item
  position: relative
  width: 100%
  height: 160px
  margin-right: 0
  margin-bottom: 10px
  padding: 5px
  border: 1px solid $grey-color
  overflow: hidden

  &_helper
    transform: scale(1.2)
    background: $white-color
    cursor: grab

  img
    width: 100%
    height: 100%
    object-fit: cover

.status-container
  padding: 15px

  h3
    margin-top: 10px

  p
    margin-bottom: 10px

  .btn-container
    margin-bottom: 0

  &.warning
    background: $warning-color

  &.success
    background: $success-color

  &.danger
    background: $danger-color

.button.btn-danger
  background-color: $chip-bg-danger

.btn-container_status
  display: flex
  align-items: center
  flex-wrap: wrap

.MuiChip-root.chip
  margin-bottom: 5px
  margin-right: 5px
  color: $white-color

  .MuiAvatar-root.MuiChip-avatar
    width: 32px
    height: 32px

  svg
    fill: $white-color
    width: 25px
    height: 25px

  &.chip-warning
    background-color: $chip-bg-warning

    .MuiAvatar-root.MuiChip-avatar
      background-color: $chip-icon-bg-warning

  &.chip-success
    background-color: $chip-bg-success

    .MuiAvatar-root.MuiChip-avatar
      background-color: $chip-icon-bg-success

  &.chip-danger
    background-color: $chip-bg-danger

    .MuiAvatar-root.MuiChip-avatar
      background-color: $chip-icon-bg-danger

.main-carinfo
  display: flex
  flex-wrap: wrap
  width: 100%
  border: 1px solid rgba(8, 124, 178, 0.3)
  border-radius: 0px
  background-color: #e1edf2
  margin-top: 20px


  &__col
    padding: 15px
    flex-grow: 1
    flex-wrap: wrap

  &__row
    display: flex
    justify-content: space-between
    flex-grow: 1
    border-bottom: 1px dotted #DDD

    span
      margin-bottom: -1px
      font-size: 14px
      background: #e1edf2
      color: #333333

      &:nth-child(2n)
        font-weight: bold
        font-size: 16px
        font-weight: 500

.main-carinfoList
  display: flex
  flex-wrap: wrap
  width: 100%
  border-radius: 0px


  &__col
    margin-top: 10px
    flex-grow: 1
    flex-wrap: wrap

  &__row
    width: 100%
    display: flex
    justify-content: space-between
    flex-grow: 1
    border-bottom: 1px dotted #DDD

    span
      margin-bottom: -1px
      font-size: 14px
      background: #fff
      color: #333333

      &:nth-child(2n)
        font-weight: bold
        font-size: 14px
        font-weight: 500

.car-edit
  display: flex
  flex-wrap: wrap
  padding: 15px
  background: $white-color
  border: 1px solid $grey-color

  &__item.MuiFormControl-root
    flex-grow: 1
    min-width: 200px
    margin-right: 10px
    margin-bottom: 15px

  .btn-container
    margin-bottom: 15px

.btn-container
  flex-basis: 100%

@media (min-width: 650px)

  .main

    .car

      &__img-container
        width: 300px
        height: 200px
        margin-right: 15px
        overflow: hidden
        margin-bottom: 0

        img
          object-fit: cover

      &__text
        flex-basis: auto

    .main-carinfoList

      &__row
        width: 300px


@media (min-width: 450px)

  .sortable-item
    position: relative
    width: 214px
    height: 143px
    margin-right: 10px
    margin-bottom: 10px
    padding: 5px
    border: 1px solid $grey-color
    cursor: pointer
    overflow: hidden

    img
      object-fit: cover

    &_helper
      transform: scale(1.2)
      background: $white-color
      cursor: grab

      img
        width: 100%
        height: auto
